.mainContainer{
    display: flex;
    flex-direction: column;
}

.pageContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.userContainer{
    box-shadow: 3px 0 3px #D5D5D5;
    display: flex;
    flex-direction: column;
    width: 30%;
    color:black;
}

.heading {
    padding-bottom: 1.2rem;
    padding-top: 3rem;
}

.topTitle {
    padding-top: 0.6rem;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.profilePicContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding-top: 10px;
    position: relative;
}

.profileDetail {
    display: flex;
    flex-direction: column;
    gap: 2.5px;
}

.profilePicContainer img {
  height: 100%;
  width: 65%;
  border-radius: 50%;
  cursor: pointer;
}

.overlay {
  position: absolute;
  height: 95%;
  width: 65%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayText {
  color: white;
  font-size: 18px;
  text-align: center;
}

.profilePicContainer:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}

.profileInfo {
    display: flex;
    flex-direction: column;
    background-color: #DCDCDC;
    height: 370px;
    padding-left: 10px;
    padding-top: 5px;
    gap: 10px;
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  height: 40px;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #555;
}

.collapsibleSection {
    width: 100%;
}

.collapsibleArea {
    box-shadow: 1px 0 6px #D5D5D5;
}

.checkboxArea{
    display: flex;
    flex-direction: row;
}

.privacyTextContainer{
    display: flex;
    flex-direction: column;
}

.profileLabel {
    display: flex;
    flex-direction: column;
}

.buttonContainer {
    margin-left: 5.5rem;
    width: 180px;
}

.modalCustomClass {
    width: 700px;
    padding: 0;
    box-sizing: border-box;
}

.headerBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    mix-blend-mode: normal;
}

.questionBox {
    display: flex;
    justify-content: space-between;
    background-color: #202D8A;
    border: 1px solid #E5E7EB;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 4px;
}
