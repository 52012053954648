@value primaryWhite200, primaryBlue100, primaryBlue500, secondaryGrey400, secondaryRed400, secondaryGrey200, secondarySeaGreen400 from '../colors/colors.module.css';

.questionBox {
    display: flex;
    justify-content: space-between;
    background-color: primaryWhite200;
    border: 1px solid secondaryGrey200;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
}

.questionBox.open {
    background-color: primaryBlue100;
    color: primaryBlue500;
    border-color: transparent;
}

.iconContainer {
    display: flex;
}

.icons {
    display: inline-block;
    vertical-align: text-bottom;
    width: 21px;
    height: 21px;
    margin: 0 10px;
    color: secondaryGrey400;
}

.trashIcon {
    color: secondaryRed400;
}

.editIcon {
    color: secondarySeaGreen400;
}

.clickable:hover {
    cursor: pointer;
}

.questionHeaderContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
}

.questionHeader {
    display: inline-block;
}

.inputContainer {
    padding-bottom: 15px;
}

.largeInputGrid > div {
    margin: 15px 0;
}

.smallInputGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.smallInputGrid > div {
    flex: 1 0 40%;
}

.addQuestionButton {
    margin: 20px 0;
    float: right;
}
