.inputContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    margin: 15px 0;
}

.inputContainer > div {
    width: inherit;
}

.inputContainer > button {
    margin: 20px 10px;
    height: fit-content;
}
