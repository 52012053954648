@value primaryBlue500, secondaryGrey300, secondaryGreen400 from '../../../../ui/colors/colors.module.css';

.transcriptContainer {
    padding: 9px 13px;
    border: 1px solid secondaryGrey300;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.headerText {
    margin-top: 20px;
    margin-bottom: 10px;
}

.blueText {
    color: primaryBlue500;
}

.transcriptHeaderContainer {
    display: flex;
    justify-content: space-between;
}

.generateButton {
    width: 165px;
}

.highlightClass {
    font-family: inherit;
    background-color: rgba(145, 200, 62, 0.5);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.highlightClass:hover {
    cursor: pointer;
    background-color: rgba(145, 200, 62, 0.8);
}

.centerLoader {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.iconContainerPopover {
    display: flex;
}
