@value primaryWhite200, primaryBlue500, secondaryGrey500 from '../../../../../ui/colors/colors.module.css';

.tableStyle {
    width: 100%;
    margin-top: 20px;
}

.tableHeader {
    background-color: primaryWhite200;
}

.tableHeader > th {
    width: auto;
    padding: 12px 6px;
    color: secondaryGrey500;
}

.assessmentListEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vh;
    color: primaryBlue500;
}
