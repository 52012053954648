@value primaryBlue500, primaryBlue300 from '../colors/colors.module.css';

.loadingBackground {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: progress;
    background-color: rgba(0,0,0,0.6);
}

.loadingBackground .loadingBar {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
}

.loadingBackground .loadingBar .loadingCircleInner,
.loadingBackground .loadingBar .loadingCircleOuter {
    content: " ";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: primaryBlue500;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.loadingBackground .loadingBar .loadingCircleOuter {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}
