@value secondaryGrey400 from '../../../../../../../ui/colors/colors.module.css';

.formContainer > div{
    margin: 10px 0;
}

.backIcon {
    width: 20px;
    transform: rotateY(180deg);
}

.backIcon:hover {
    color: secondaryGrey400;
    cursor: pointer;
}
