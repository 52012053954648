@value secondaryGrey500 from '../../../ui/colors/colors.module.css';

.videoStreamContainer {
    width: 256px;
    height: 192px;
    border-radius: 10px;
}

.buttonStyles {
    width: 180px;
    height: 40px;
}

.faceArea{
    display: flex;
    flex-direction: row;
}

.photoArea {
    display:flex;
    flex-direction: column;
    padding-left:6rem;
    gap: 20px;
}

.liveCamera {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: -0.5rem;
}

.faceArea{
    display: flex;
    flex-direction: row;
}

.faceDetailsArea {
    display: flex;
    flex-direction: row;
    gap: 0%;
}

.faceDetail {
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
}

.faceInfo {
    display: flex;
    flex-direction: row;
}

.buttonSection {
    display: flex;
    flex-direction: row;
    gap: 67.5px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.tickCrossArea {
    display: flex;
    gap: 5px;
    margin-right: 65px;
    padding-left: 3rem;
}

.faceAnalysis {
    display:flex;
    flex-direction: column;
    width: 100%;
    gap:20px;
}

.camLabels {
    margin-left: 4rem;
}

.camSection {
    margin-top: -20px;
    display: flex;
    flex-direction: column;
}
