@value primaryBlue300, primaryBlue500 from '../../../../ui/colors/colors.module.css';

.backButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: primaryBlue500;
    gap: 3px;
}

.backButtonContainer:hover {
    cursor: pointer;
    color: primaryBlue300;
}

.backIconClass {
    transform: rotate(180deg);
    width: 15px;
}

.backIconClass > svg{
    stroke-width: 4px;
}

.emptySect {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}
