.flexDirectionRow {
    display: flex;
    flex-direction: row;
}

.searchBarContainer {
    gap: 10px;
}

.searchBarContainer > button {
    margin: 0;
    height: 50%;
}

.questionResultContainer {
    margin: 10px 0;
}

.paginationContainer {
    align-items: center;
    justify-content: space-between;
}

.questionNumberInputContainer {
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 10%;
}
