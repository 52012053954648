@value primaryBlue100, primaryWhite200 from '../../../../../ui/colors/colors.module.css';

.assessmentHeader {
    background-color: primaryBlue100;
    padding: 22px 40px;
}

.videoStreamContainer {
    width: 100%;
    border-radius: 8px;
}

.bottomButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 20px 20px 20px;
}
