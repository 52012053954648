@value primaryBlue500 from '../../../ui/colors/colors.module.css';

.flexRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.marginIconGroup {
    margin: 10px 0;
}

.rowGap {
    gap: 20px;
    opacity: 65%;
}

.iconGap {
    gap: 5px;
}

.icons {
    width: 24px;
    height: 24px;
}

.searchContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
    align-items: center;
}

.blueText {
   color: primaryBlue500;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.blueText:hover {
    cursor: pointer;
    opacity: 70%;
}

.blueText {
    color: primaryBlue500;
}

.plusIconColor > svg > path {
    fill: primaryBlue500;
}
