.questionAreaContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.questionAreaContainer > video {
    transform: scaleX(-1);
}

.questionAreaContainer > video::-webkit-media-controls-enclosure {
    transform: scaleX(-1);
}

.questionAreaContainer > div {
    width: 100%;
}

.answerAreaHeight {
    height: 300px;
}

.mediaContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.videoContainer {
    width: 480px;
    height: 270px;
    align-self: center;
}

.recordInteractionButton {
    width: 20vw;
    border-radius: 34px;
    align-self: center;
}
