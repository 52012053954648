@value primaryWhite200, secondaryGrey200, secondaryRed400, primaryBlue100, primaryBlue200 from '../../../../../../ui/colors/colors.module.css';

.tableRow {
    outline: secondaryGrey200 solid 1px;
}

.practice {
  background-color: primaryBlue100;
}

.tableRow:hover {
    cursor: pointer;
    background-color: primaryWhite200;
}

.practice:hover {
  background-color: primaryBlue200;
}

.tableRow > td {
    width: auto;
    padding: 12px 0;
}

.toggleButtonCell {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: unset !important;
}

.deleteIcon {
    height: 22px;
    width:22px;
}

.practiceIcon {
    color: grey;
}

.notPracticeIcon {
    color: secondaryRed400;
}

.assessmentTypeText{
    text-transform: capitalize;
}
