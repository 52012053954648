@value primaryWhite200, secondaryGrey200, secondaryGrey400, secondaryGreen400, secondaryRed400, primaryBlue500 from '../../../../../ui/colors/colors.module.css';

.tableRow {
    outline: secondaryGrey200 solid 1px;
}

.tableRow > td {
    width: auto;
}

.activeCell {
    display: flex;
    justify-content: center;
}

.activeCell > div {
    margin: 10px 0;
}

.deleteIcon {
    color: secondaryRed400;
    height: 25px;
    width: 25px;
}

.deleteIcon:hover {
    cursor: pointer;
}

.statusText {
    color: secondaryGrey200
}

.statusText.complete {
    color: secondaryGreen400
}

.statusText.inComplete {
    color: primaryBlue500
}

.inputContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    margin: 15px 0;
}

.inputContainer > div {
    width: inherit;
}

.inputContainer > button {
    margin: 20px 10px;
}
