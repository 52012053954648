@value primaryWhite100, secondaryGrey400 from '../colors/colors.module.css';
@value animationDistance: 20vh;

.modal {
    background: primaryWhite100;
    border-radius: 5px;
    width: 35vw;
    margin: auto;
    padding: 20px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
    animation-name: animateModal;
    animation-duration: 500ms;
    z-index: 10;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.modal.open {
    transform: translateY(animationDistance);
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
}

.close.none {
    display: none;
}

.close:hover {
    color: secondaryGrey400;
    cursor: pointer;
}

@keyframes animateModal {
    from {
        transform: translateY(0);
        opacity: 0
    }
    to {
        transform: translateY(animationDistance);
        opacity: 1
    }
}

@media (max-width: 640px) { 
    .modal {
        width: 100vw;
        max-height: 100vh;
        height: 100vh;
        transform: translateY(0) !important;
    }
}
