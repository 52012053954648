@value primaryWhite200, secondaryGrey500 from '../../../../ui/colors/colors.module.css';

.documentTriage {
  display: flex;
  flex-direction: column;
}

.header {
  background-color: primaryWhite200;
  color: secondaryGrey500;
  word-break: break-all;
}
