@value primaryWhite200, primaryBlue500, secondaryBlue400 from '../colors/colors.module.css';

.questionListContainer {
    width: 100%;
}

.questionHeader {
    margin-top: 20px;
    margin-bottom: 10px;
    color: primaryBlue500;
}

.questionListSummary {
    display: flex;
    justify-content: space-between;
    background-color: primaryWhite200;
    margin: 16px 0;
    padding: 16px;
    border-radius: 4px;
}

.questionListSummary > p {
    color: secondaryBlue400;
}
