@value primaryBlue500, secondaryGrey200 from '../../../../ui/colors/colors.module.css';

.tabButtonContainer {
    display: flex;
    border-bottom: 1px solid secondaryGrey200;
}

.tabButton {
    padding: 8px 18px;
}

.tabButton:hover {
    cursor: pointer;
}

.tabButton.selected {
    color: primaryBlue500;
    border-bottom: 3px solid primaryBlue500;
}
