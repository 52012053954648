/* Primary font: Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Secondary font: Inter */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@value fontSizePrimaryTypographyMedium: 1rem;
@value fontSizePrimaryTypographyLarge: 1.125rem;
@value fontSizePrimaryTypographyXLarge: 1.25rem;
@value fontSizePrimaryTypography2XLarge: 2rem;
@value fontSizePrimaryTypography3XLarge: 2rem;
@value fontSizePrimaryTypography4XLarge: 2rem;
@value fontSizePrimaryTypography5XLarge: 2.25rem;
@value fontSizePrimaryTypography6XLarge: 2.5rem;
@value fontSizePrimaryTypography7XLarge: 2.75rem;
@value fontSizeSecondaryTypographyXSmall: 0.75rem;
@value fontSizeSecondaryTypographySmall: 0.875rem;
@value fontSizeSecondaryTypographyMedium: 1rem;
@value fontSizeSecondaryTypographyLarge: 1.125rem;
@value fontSizeSecondaryTypographyXLarge: 1.25rem;

@value lineHeightPrimaryTypographyMedium: 1.5rem;
@value lineHeightPrimaryTypographyLarge: 1.5rem;
@value lineHeightPrimaryTypographyXLarge: 1.75rem;
@value lineHeightPrimaryTypography2XLarge: 1.75rem;
@value lineHeightPrimaryTypography3XLarge: 2rem;
@value lineHeightPrimaryTypography4XLarge: 2.5rem;
@value lineHeightPrimaryTypography5XLarge: 3rem;
@value lineHeightPrimaryTypography6XLarge: 3.5rem;
@value lineHeightPrimaryTypography7XLarge: 3.75rem;
@value lineHeightSecondaryTypographyXSmall: 1rem;
@value lineHeightSecondaryTypographySmall: 1.5rem;
@value lineHeightSecondaryTypographyMedium: 1.5rem;
@value lineHeightSecondaryTypographyLarge: 1.5rem;
@value lineHeightSecondaryTypographyXLarge: 1.75rem;

.primary {
    font-family: 'Montserrat', sans-serif;
}

.h6.medium {
    font-size: fontSizePrimaryTypographyMedium;
    line-height: lineHeightPrimaryTypographyMedium;
}

.h6.large {
    font-size: fontSizePrimaryTypographyLarge;
    line-height: lineHeightPrimaryTypographyLarge;
}

.h5.xLarge {
    font-size: fontSizePrimaryTypographyXLarge;
    line-height: lineHeightPrimaryTypographyXLarge;
}

.h4.twoXLarge {
    font-size: fontSizePrimaryTypography2XLarge;
    line-height: lineHeightPrimaryTypography2XLarge;
}

.h4.threeXLarge {
    font-size: fontSizePrimaryTypography3XLarge;
    line-height: lineHeightPrimaryTypography3XLarge;
}

.h4.fourXLarge {
    font-size: fontSizePrimaryTypography4XLarge;
    line-height: lineHeightPrimaryTypography4XLarge;
}

.h3.fiveXLarge {
    font-size: fontSizePrimaryTypography5XLarge;
    line-height: lineHeightPrimaryTypography5XLarge;
}

.h2.sixXLarge {
    font-size: fontSizePrimaryTypography6XLarge;
    line-height: lineHeightPrimaryTypography6XLarge;
}

.h1.sevenXLarge {
    font-size: fontSizePrimaryTypography7XLarge;
    line-height: lineHeightPrimaryTypography7XLarge;
}

.secondary {
    font-family: 'Inter', sans-serif;
}

p.xSmall {
    font-size: fontSizeSecondaryTypographyXSmall;
    line-height: lineHeightSecondaryTypographyXSmall;
}

p.small {
    font-size: fontSizeSecondaryTypographySmall;
    line-height: lineHeightSecondaryTypographySmall;
}

p.medium {
    font-size: fontSizeSecondaryTypographyMedium;
    line-height: lineHeightSecondaryTypographyMedium;
}

p.large {
    font-size: fontSizeSecondaryTypographyLarge;
    line-height: lineHeightSecondaryTypographyLarge;
}

p.xLarge {
    font-size: fontSizeSecondaryTypographyXLarge;
    line-height: lineHeightSecondaryTypographyXLarge;
}

.removeMargins {
    margin: 0;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.justify {
    text-align: justify;
}

.italicized {
    font-style: italic;
}

.light {
    font-weight: 300;
}

.normal {
    font-weight: normal;
}

.semiBold {
    font-weight: 500;
}

.bold {
    font-weight: 700;
}

.none {
    text-transform: none;
}

.lowercase {
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}
