@value primaryWhite100, primaryBlue100 from '../../../../../../ui/colors/colors.module.css';

.container {
    margin: 20px 0;
}

.searchBarContainer {
    display: flex;
    flex-direction: row;
}

.searchBar {
    position: relative;
    width: 60%;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
}

.searchRowButtons {
    margin: 0 0 0 10px;
}

.searchResults {
    position: absolute;
    background-color: primaryWhite100;
    box-shadow: 0 16px 30px rgba(55, 92, 170, 0.15);
    border-radius: 8px;
    overflow: auto;
    width: 100%;
    max-height: 20vh;
    margin-top: 3px;
}

.searchResultItem {
    padding: 10px
}

.searchResultItem:hover {
    background: primaryBlue100;
    cursor: pointer;
}
