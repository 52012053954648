@value secondaryBlue400 from '../../../../../../ui/colors/colors.module.css';

.buttonContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.assessmentInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.marksTextContainer {
    color: secondaryBlue400;
}
