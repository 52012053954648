.modalHeader {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
}

.selectTypeContainer {
    display: flex;
    flex-direction: row;
}

.buttonCustom {
    width: 160px;
    align-self: center;
    margin-top: 30px;
}
