@value primaryBlue100, primaryGradient100, primaryBlue500, secondaryGrey500, secondaryGrey200 from '../../../../../ui/colors/colors.module.css';

.selectTypeButtons {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 2px solid primaryBlue100;
    padding: 20px 16px;
    margin: 0 10px;
}

.selectTypeButtons:hover {
    cursor: pointer;
}

.selectTypeButtons.selected {
    border: 4px solid primaryBlue500;
    border-radius: 8px;
    box-shadow: 0 16px 30px rgba(55, 92, 170, 0.15);
}

.checkIcon {
    width: 32px;
    color: secondaryGrey200;
    align-self: center;
}

.checkIcon.selected {
    color: primaryBlue500;
}

.selectTypeText {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    color: secondaryGrey500;
}
