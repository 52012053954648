.rowContainer {
  padding: 16px 24px;
  display: flex;
}

.item {
  flex: 1;
  display: flex;
  align-items: center;
}
