@value secondaryGrey400, secondaryGrey500 from '../../../ui/colors/colors.module.css';

.mediaPreviewContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px;
}

.imageVideoContainer {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.iconClass {
    cursor: pointer;
    width: 25px;
    height: 25px;
}

.iconClass:hover {
    cursor: pointer;
    color: secondaryGrey400;
}

.uploadButtonContainer {
    display: flex;
    align-items: center;
}

.textClass{
    color: secondaryGrey500;
}
