@value primaryWhite100, primaryWhite200, primaryBlue100, secondaryBlue400, secondaryRed400, secondaryGrey500, secondaryGrey700 from '../../../../ui/colors/colors.module.css';

.container {
    width: 100%;
    min-height: 100vh;
}

.contentContainer {
    width: 100%;
    height: fit-content;
    background-color: primaryWhite100;
    box-shadow: 0 16px 30px rgba(55, 92, 170, 0.15);
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 15px;
    overflow: hidden;
}

.modalCustomClass {
    width: 700px;
    padding: 0;
    box-sizing: border-box;
}

.headerBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    mix-blend-mode: normal;
}

.headerBackground > div:hover {
    color: secondaryGrey700;
}

.headerBackgroundImage {
    background-image: linear-gradient(270.18deg, rgba(41, 170, 227, 0.8) 0.12%, rgba(59, 182, 75, 0.8) 99.8%),
    url('../../../../assets/images/backgrounds/login_register_bg.png');
    color: primaryWhite100;
    height: 200px;
}

.modalContentContainer {
    padding: 30px 50px;
}

.assessmentModalButton {
    margin: 10px 0 0 0;
}

.iconInfoContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.iconClass {
    width: 25px;
    height: 25px;
}

.greyText {
    color: secondaryGrey500;
}

.darkBlueText {
    color: secondaryBlue400;
}

.redText{
    color: secondaryRed400;
}

@media (max-width: 640px) { 
    .modalCustomClass {
        width: 100vw;
    }

    .modalContentContainer {
        padding: 20px 20px;
    }  
}
