.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.fileInfoContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
