@value primaryBlue300, primaryBlue500, secondarySeaGreen200 from '../../../../../ui/colors/colors.module.css';

.backButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: primaryBlue500;
    gap: 3px;
}

.backButtonContainer:hover {
    cursor: pointer;
    color: primaryBlue300;
}

.backIconClass {
    transform: rotate(180deg);
    width: 15px;
    height: 15px;
}

.backIconClass > svg{
    stroke-width: 4px;
}

.flexInlineRow {
    display: flex;
    flex-direction: row;
}

.capsuleCommonStyles {
    align-items: center;
    font-weight: 500;
    margin: 5px 0;
    font-size: 0.875em;
}

.skillSummaryCapsule {
    background-color: secondarySeaGreen200;
    padding: 4px;
    border-radius: 5px
}
