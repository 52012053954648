@value primaryWhite200, primaryBlue500 from '../../../../ui/colors/colors.module.css';

.uploadContainer {
    display: flex;
}

.uploadAreaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    padding: 15px;
    margin: 15px 0;
    background-color: primaryWhite200;
    border: 2px dashed primaryBlue500;
    border-radius: 8px;
}

.mainDivision {
    width: 100%;
    height: 100%;
    display: block;;
    align-items: center;
}

.fileDivision {
    width: 60%;
    display: block;
    align-items: start;
    margin-top: 20px;
    float: left;
}

.statusDivision {
    width: 40%;
    display: block;
    align-items: end;
    margin-top: 20px;
    float: left;
}

.uploadIcon {
    width: 25px;
    height: 25px;
    color: primaryBlue500;
}

.uploadIcon:hover {
    filter: brightness(85%);
    cursor: pointer;
}

.uploadedFileStatusContainer {
    margin: 15px 15px;
    width: 15%;
}

.uploadedFileNameContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.topicInput {
    width: 40%;
}
