.modalCustomClass {
    width: 950px;
    padding: 0;
    box-sizing: border-box;
}

.headerBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    mix-blend-mode: normal;
}