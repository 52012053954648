.filterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 15px;
}

.filterContainer > svg {
    stroke: grey;
}
