@value primaryBlue500, secondaryBlue400, secondaryBlue500, secondaryGrey600, secondaryGrey700, secondaryRed400 from '../../ui/colors/colors.module.css';
@value mobileBreakpoint, tabletBreakpoint from '../../utils/breakpoints/breakpoints.module.css';
@value inputPadding: 8px;

.pageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: secondaryGrey700;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
}

.leftContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: secondaryGrey600;
    color: white;
    width: 100%;
    height: 0%;
    font-size: 2em;
}

.leftContainerBg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.logoStyles {
    width: 50%;
}

.formContainer {
    width: 100%;
    height: 100%;
    background-color: white;
    box-sizing: border-box;
    padding: 40px 50px;
}

.formHeading {
    color: secondaryGrey600;
    margin-block-start: 1vh;
    margin-block-end: 1vh;
    font-weight: 800;
}

.form {
    display: flex;
    flex-direction: column;
}

.nameFieldContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-bottom: 12px;
}

.invalidInput {
    margin-top: 1px;
    font-size: 0.6rem;
    color: secondaryRed400;
    font-weight: 600;
}

.portalSwitcher {
    font-weight: 500;
    font-size: 0.875rem;
    color: secondaryGrey600;
}

.portalSwitcher a {
    font-weight: 700;
    text-decoration: none;
    color: secondaryBlue400;
}

.portalSwitcher a:hover {
    color: secondaryBlue500;
}

.submitButton {
    padding: inputPadding;
    width: unset;
    height: unset;
    margin: unset;
}

.resetPasswordModal {
    width: 20%;
}

.marginClass > * {
    margin: 10px 0;
}

/*Tablet View*/
@media screen and (min-width: mobileBreakpoint) {
    .mainContainer {
        flex-direction: row;
        width: 80%;
        min-height: 50vh;
        height: fit-content;
        border-radius: 10px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        overflow: hidden;
        font-size: 0.75rem;
    }

    .mainContainer > div {
        height: unset;
    }

    .leftContainer {
        width: 50%;
    }

    .formContainer {
        width: 50%;
    }
}

/*Desktop View*/
@media screen and (min-width: tabletBreakpoint) {
    .mainContainer {
        width: 60%;
        min-height: 60vh;
        height: fit-content;
    }

    .leftContainer {
        width: 60%;
    }

    .formContainer {
        width: 40%;
    }
}

.tickCrossArea {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}
