@value secondaryGrey500, primaryBlue500, primaryGradient100, primaryWhite100 from '../../../../../ui/colors/colors.module.css';

.flexInlineRow {
    display: flex;
    flex-direction: row;
}

.infoBox {
    display: flex;
    width: 15%;
    gap: 20px;
    box-shadow: 0 16px 30px rgba(55, 92, 170, 0.15);
    border-radius: 6px;
    margin: 10px;
    padding: 10px 24px;
}

.iconClass {
    background: primaryGradient100;
    color: primaryWhite100;
    width: auto;
    height: auto;
    padding: 12px;
    border-radius: 6px;
}

.infoBoxText {
    align-self: center;
}

.infoBoxSmallText {
    color: secondaryGrey500;
}

.sortCol {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.sortArrows {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.activateArrow {
    fill:primaryBlue500;
}

.deactivateArrow {
    fill: #6B7280;
}

.emptySect {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25vh;
}