@value primaryBlue500, primaryBlue100, primaryWhite100, primaryGradient100, secondaryRed100, secondaryRed300, secondaryRed400, secondarySeaGreen100, secondarySeaGreen300, secondarySeaGreen400, secondaryGrey300, secondaryGrey400 from '../colors/colors.module.css';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 10px;
    border-radius: 5px;
    background-color: primaryWhite100;
    white-space: nowrap;
}

.button:hover:enabled {
    cursor: pointer;
}

.button:disabled {
    background-color: secondaryGrey300;
    cursor: not-allowed;
}

.button:active:not([disabled]) {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 2px primaryWhite100, 0 0 0 4px primaryBlue500;
}

.button[aria-selected='true'] {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 2px primaryWhite100, 0 0 0 4px primaryBlue500;
}

.buttonIcon {
    display: flex;
    width: 20px;
    margin-right: 5px;
}

.primary {
    background-color: primaryBlue500;
    color: primaryWhite100;
    border: unset;
}

.primary:hover {
    background: primaryGradient100;
}

.primary:hover:disabled {
    background: secondaryGrey300;
}

.secondary {
    color: primaryBlue500;
    border: 1px solid primaryBlue500;
}

.secondary:hover {
    background: primaryBlue100;
}

.secondary:disabled {
    border-color: secondaryGrey400;
    color: secondaryGrey400;
}

.secondary:hover:disabled {
    background: secondaryGrey300;
}

.secondary[aria-selected='true'] {
    background: primaryBlue100;
}

.success {
    color: secondarySeaGreen400;
    border: 1px solid secondarySeaGreen300;
}

.success:hover {
    background: secondarySeaGreen100;
}

.success:active:not([disabled]) {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 2px primaryWhite100, 0 0 0 4px secondarySeaGreen400;
}

.success[aria-selected='true'] {
    background: secondarySeaGreen100;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 2px primaryWhite100, 0 0 0 4px secondarySeaGreen400;
}

.danger {
    color: secondaryRed400;
    border: 1px solid secondaryRed300;
}

.danger:hover {
    background: secondaryRed100;
}

.danger:active:not([disabled]) {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 2px primaryWhite100, 0 0 0 4px secondaryRed400;
}

.danger[aria-selected='true'] {
    background: secondaryRed100;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 2px primaryWhite100, 0 0 0 4px secondaryRed400;
}

.xSmall {
    font-size: 0.75rem;
    line-height: 1rem;
    Padding: 7px 11px 7px 11px;
}

.small {
    font-size: 0.875rem;
    line-height: 1rem;
    Padding: 9px 13px 9px 13px;
}

.medium {
    font-size: 0.875rem;
    line-height: 1.188rem;
    Padding: 9px 17px 9px 17px;
}

.large {
    font-size: 1rem;
    line-height: 1.5rem;
    Padding: 8px 20px 8px 20px;
}

.xLarge {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 16px 20px 16px 20px;
}

@media (max-width: 640px) { 
    .button {
        white-space: unset;
        word-wrap: break-word;
    }
}
