@value primaryWhite200, primaryBlue100, primaryBlue500, secondaryGrey500, secondaryGrey700, secondarySeaGreen400 from '../../../../../../../ui/colors/colors.module.css';

.questionAnswerContainer {
    display: flex;
    flex-direction: row;
    grid-gap: 60px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.userAnswerContainer {
    width: 40%;
    position: relative;
}

.userVideo {
    position: relative;
    width: 100%;
    height: auto;
}

.userVideo video {
    width: 640px;
    height: 480;
}

.blurOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);
  pointer-events: none;
}

.userVideoLive {
    position: relative;
    width: 100%;
    height: auto;
}

.userVideoLive video {
    text-align: center;
    width: 500px;
    height: 220px;
}

.pointsInfoContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pointsText {
    color: secondarySeaGreen400;
}

.linkText {
    color: primaryBlue500;
    text-decoration: underline primaryBlue500;
}

.greyText {
    color: secondaryGrey500;
}

.tabContainer {
    width: 40%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    background-color: primaryWhite200;
    border-radius: 6px;
    height: fit-content;
}

.answerTextArea {
    height: 125px;
}

.tabs {
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
    overflow: hidden;
}

.tabs > div {
    width: 100%;
    padding: 16px 60px;
    color: secondaryGrey700;
}

.tabs > div:hover {
    cursor: pointer;
}

.selectedTab {
    background-color: primaryBlue100;
    border-bottom: 2px solid primaryBlue500;
    color: primaryBlue500 !important;
}

.tabTextArea > div {
    margin-top: 10px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
    grid-gap: 10px;
    justify-content: flex-end;
}

.buttonClass {
    margin: 0;
}
