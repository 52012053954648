@value secondaryGrey500 from '../../../../../../ui/colors/colors.module.css';

.userAssessmentListItem {
    margin: 15px 0;
}

.userAssessmentDetailsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.userButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
