@value primaryBlue500, secondaryRed400, secondaryGrey200 from '../../../../ui/colors/colors.module.css';

.treeRowContainer {
    display: flex;
    justify-content: space-between;
}

.expansionIcons {
    display: flex;
    padding: 5px;
    gap: 10px;
}

.expansionIcons > svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
}

.rightIcons {
    display: flex;
    gap: 6px;
}

.rightIcons > div > svg {
    width: 18px;
    height: 25px;
    cursor: pointer;
}

.rightIcons > div:nth-child(1) > svg {
    color: primaryBlue500; 
}

.rightIcons > div:nth-child(3) > svg {
    color: secondaryRed400;
}

.dropTarget {
    border: 2px dashed primaryBlue500;
}
  
.dragging {
    opacity: 0.5;
}
