@value primaryGradient100, primaryWhite100, secondaryBlue400, secondaryBlue500 from '../../colors/colors.module.css';
@value barHeight from '../sidebar.module.css';

.menuItems {
    background-color: secondaryBlue500;
    width: 70px;
    height: 100vh;
    margin: barHeight 0 0 0;
    list-style: none;
    padding: unset;
    position: fixed;
}

.menuItems a {
    display: flex;
    background-color: secondaryBlue400;
    margin: 34px 14px;
    color: white;
    padding: 12px;
    border-radius: 26px;
}

.menuItems a:hover {
    background: primaryGradient100;
    cursor: pointer;
}

.menuItems a[aria-selected=true] {
    background: primaryGradient100;
    cursor: pointer;
}

.tooltip {
    /* position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; */
}

.tooltip .tooltipText {
    visibility: hidden;
    width: 120px;
    font-size: 15px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 6px;
    left: 140%;
}

.tooltip .tooltipText::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltipText {
    visibility: visible;
}