.questionBox {
    display: flex;
    justify-content: space-between;
    background-color: #F9F9F9;
    border: 1px solid #E5E7EB;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    padding: 15px;
    margin: 10px 0;
    border-radius: 4px;
}

.questionBox.open {
    background-color: #D9EDF8;
    color: #29AAE2;
    border-color: transparent;
}

.questionHeaderContainer {
    display: flex;
    flex-direction: row;
    width: 98%;
}

.questionHeader {
    display: inline-block;
}

.iconContainer {
    display: flex;
}

.clickable:hover {
    cursor: pointer;
}

.icons {
    display: inline-block;
    vertical-align: text-bottom;
    width: 35px;
    height: 30px;
    color: #9CA3AF;
}

.inputContainer {
    padding-bottom: 15px;
}

.largeInputGrid {
    padding-left:5px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.largeInputGrid > div {
    flex: 1 0 40%;
}

.buttonStyles {
    margin-left: 55rem;
    width: 180px;
}

.privacyInfo {
    padding-left:12px;
}

