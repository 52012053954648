@value secondaryGrey500 from '../colors/colors.module.css';

.searchBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchIcon {
    width: 20px;
    color: secondaryGrey500;
}

.searchBarContainer > button {
    align-self: flex-start;
    margin: 3px 10px;
}
