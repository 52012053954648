@value secondaryGrey600 from '../colors/colors.module.css';

.largeInputGrid > div {
    margin: 15px 0;
}

.smallInputGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.smallInputGrid > div {
    flex: 1 0 40%;
}

.addQuestionButton {
    margin: 0;
    height: 50px;
    align-self: end;
}

.uploadMediaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.uploadMediaText {
    color: secondaryGrey600;
}
