@value secondaryGrey300 from '../../../../../../ui/colors/colors.module.css';

.documentDetailsContainer {
    padding: 10px;
}

.transcriptContainer {
    padding: 9px 13px;
    border: 1px solid secondaryGrey300;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin-bottom: 25px;
}

.questionAnswerContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
}

.questionAnswerContainer > div {
    margin: 10px 0;
    width: 50%;
}
