.inviteUsers {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.centerEmailLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
}

.inviteModalClass {
    width: 20%;
}
