@value primaryWhite200, secondaryGrey200, secondaryGrey400, secondaryRed400, secondarySeaGreen400 from '../../../ui/colors/colors.module.css';

.tableRowStyle {
    box-sizing: border-box;
    padding: 10px;
    margin: 10px 10px;
    border-radius: 4px;
}

.tableRowStyle td:not(:first-child) {
    background-color: primaryWhite200;
    border: 1px solid secondaryGrey200;
    border-left-width: 0;
    border-right-width: 0;
}

.tableRowStyle.leftBorder {
    border-left-width: 1px !important;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.tableRowStyle.rightBorder {
    border-right-width: 1px !important;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.iconContainer {
    float: right;
}

.icons {
    display: inline-block;
    vertical-align: text-bottom;
    width: 21px;
    height: 21px;
    margin: 0 10px;
    color: secondaryGrey400;
}

.icons:hover {
   cursor: pointer;
}

.trashIcon {
    color: secondaryRed400;
}

.editIcon {
    color: secondarySeaGreen400;
}
