@value primaryWhite200, secondarySeaGreen400, secondaryYellow400, secondaryRed400 from '../../../../../../../ui/colors/colors.module.css';

.statusCapsule {
    border-radius: 10px;
    background-color: primaryWhite200;
    padding: 2px 8px;
    display: inline-block;
    font-weight: bold;
    font-size: 0.9em;
    word-break: break-all;
}

.pass {
    color: secondarySeaGreen400;
}

.fail {
    color: secondaryRed400;
}
