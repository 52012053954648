.container {
    width: 100%;
    height: 100vh;
}

.modalDimensions {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 5px 20px 20px;
}
