@value secondaryGrey200, primaryWhite100, primaryBlack900, secondaryGrey500, secondaryGrey600, secondaryGrey900 from '../../colors/colors.module.css';

.profileContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 auto;
}

.profileInfo p {
    margin: 4px 8px;
    font-size: 0.875rem;
}

.profileAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.username {
    font-weight: 500;
    color: secondaryGrey900;
}

.userRole {
    font-weight: 400;
    color: secondaryGrey500;
}

.chevronContainer {
    width: 25px;
    height: 25px;
    color: secondaryGrey500;
    cursor: pointer;
}

.dropdown {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background-color: secondaryGrey200;
    color: primaryBlack900;
    font-weight: 500;
    font-size: 0.875rem;
    transform: translateY(-75px);
    transition: opacity 500ms, transform 500ms;
    border-radius: 3px;
    text-align: center;
    padding: 6px 0;
}

.dropdown.open {
    opacity: 1;
    visibility: visible;
    transform: translateY(90px);
    transition: opacity 750ms, transform 750ms;
}

.dropdownItem {
    padding: 6px 20px;
    color: secondaryGrey600;
}

.dropdownItem:hover {
    cursor: pointer;
    color: secondaryGrey900;
    background-color: primaryWhite100;
}
