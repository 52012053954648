@value primaryWhite100, primaryBlack900 from '../colors/colors.module.css';

.filterBoxBottomBorder {
    border-bottom: 1px solid primaryBlack900;
}
.filterBox {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
}

.filterBox.open {
    background-color: primaryWhite100;
    border-color: transparent;
}

.iconContainer {
    display: flex;
}

.icons {
    display: inline-block;
    vertical-align: text-bottom;
    width: 21px;
    height: 21px;
    margin: 0 10px;
    color: secondaryGrey400;
}

.clickable:hover {
    cursor: pointer;
}

.filterHeaderContainer {
    display: flex;
    flex-direction: row;
    width: 90%;
}

.filterHeader {
    display: inline-block;
    text-decoration-line: underline;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inputSubContainer {
    padding-bottom: 15px;
    background-color: primaryWhite100;
    padding: 10px;
    gap: 15px;
    margin-bottom: 10px;
}

.selectContainer {
    min-width: 250px;
    max-width: 400px;
    z-index: 11;
}

.listItemLabel {
    margin-left: 4px;
}

.datePicker {
    border-width: 1px;
    border-radius: 4px;
    border-color: hsl(0, 0%, 80%);
    width: 100%;
    position: relative;
    z-index: 11;
}
