.dataContainer:fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 8rem solid gray;
}

.numeriacalContainer {
  height: 150px;
}

.noDataContainer {
  height: 120px;
}
