@value primaryWhite100, primaryBlue500, secondaryGrey500, secondaryGrey300 from '../colors/colors.module.css';

.labelStyles {
    color: secondaryGrey500;
}

.inputStyles {
    display: block;
    background: primaryWhite100;
    padding: 9px 13px;
    border: 1px solid secondaryGrey300;
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-top: 3px;
}

.inputStyles:focus {
    outline: none !important;
    border: 1px solid primaryBlue500;
}
