@value primaryBlue500, secondaryGrey500, secondaryGrey900 from '../colors/colors.module.css';

.controllerContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
}

.controller {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: fit-content;
    height: fit-content;
    border: 2px primaryBlue500 solid;
    border-radius: 20px;
}

.navigationElement {
    padding: 8px;
    color: secondaryGrey500;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.navigationButton {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.navigationButton.disabled {
    pointer-events: none;
    cursor: default;
}

.navigationButton > svg {
    width: 24px;
    height: 24px;
}

.navigationButton:hover {
    color: secondaryGrey900;
}
