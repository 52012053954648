@value primaryGreen400, primaryGreen500, primaryWhite100, secondaryBlue100, secondaryBlue400, secondaryBlue500, secondaryGrey500 from '../../../colors/colors.module.css';
@value barHeight from '../../sidebar.module.css';
@value menuWidth: 200px;

.sideMenu {
    position: fixed;
    top: barHeight;
    background-color: primaryWhite100;
    width: menuWidth;
    height: 100vh;
    transform: translateX(-115px);
    border-right: 2px solid secondaryBlue100;
}

.sideMenu.active {
    transform: translateX(70px);
}

.toggleButton {
    background-color: primaryWhite100;
    position: absolute;
    border-radius: 12px;
    border: 1px solid secondaryBlue100;
    width: 20px;
    height: 20px;
    right: -10px;
    top: 10px;
    color: secondaryBlue400;
}

.toggleButton:hover {
    cursor: pointer;
}

.toggleButton > div:hover {
    color: secondaryBlue500;
}

.chevron {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.40em;
    left: 0.35em;
    position: relative;
    transform: rotate(225deg);
    width: 0.40em;
    transition: 500ms;
}

.chevron.open {
    transform: rotate(45deg);
    left: 0.20em;
}

.subMenuHeader {
    padding: 10px 12px;
    margin: 0;
    text-transform: uppercase;
    color: secondaryGrey500;
    font-weight: 800;
    font-size: 0.875rem;
}

.menuSubItems {
    list-style: none;
    transition: 400ms;
    padding: 0;
    margin: 0;
}

.menuSubItemLink {
    display: flex;
    color: secondaryBlue400;
    padding: 10px 12px;
    transition: 500ms;
    font-weight: 700;
    font-size: 0.875rem;
}

.menuSubItemLink.selected {
    color: primaryGreen500;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.menuSubItemLink:not(.selected):hover {
    color: primaryGreen400;
    cursor: pointer;
}
