@value primaryWhite200, secondaryGrey400, secondarySeaGreen200, secondarySeaGreen400, secondaryRed400 from '../../../../../../ui/colors/colors.module.css';

.container {
    display: flex;
    margin: 8px 0;
    grid-gap: 10px;
}

.questionResultContainer {
    display: flex;
    justify-content: space-between;
    background-color: primaryWhite200;
    padding: 8px 24px;
    width: 100%;
    border-radius: 4px;
}

.questionResultContainer.approved {
    background-color: secondarySeaGreen200
}

.infoAndToggleContainer {
    display: flex;
    grid-gap: 10px;
    color: secondaryGrey400;
    white-space: nowrap;
}

.iconClass {
    width: 24px;
    height: 24px;
}

.correct {
    color: secondarySeaGreen400;
}

.incorrect {
    color: secondaryRed400;
}

.chevron:hover {
    cursor: pointer;
}
