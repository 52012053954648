@value primaryBlue500 from '../../../../../ui/colors/colors.module.css';

.assessmentCompleteContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lightBlueText {
    color: primaryBlue500;
 }

.buttonContainer {
    display: flex;
}
