@value primaryWhite100, primaryBlue100, secondaryGrey400 from '../../colors/colors.module.css';

.searchBarContainer {
    position: relative;
    width: 100%;
}

.searchResults {
    background-color: primaryWhite100;
    box-shadow: 0 16px 30px rgba(55, 92, 170, 0.15);
    border-radius: 8px;
    overflow: auto;
    width: 100%;
    max-height: 20vh;
    margin-top: 3px;
}

.paginationContainer {
    position: sticky;
    position: -webkit-sticky;
    background-color: primaryWhite100;
}

.searchResultItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}

.searchResultItem:hover {
    background: primaryBlue100;
    cursor: pointer;
}

.questionInfo {
    display: flex;
    align-items: center;
}

.questionIcon {
    color: secondaryGrey400;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.addQuestionButton {
    border-radius: 12px;
    padding: 4px 15px;
    margin: 8px 0;
}
