.graphicsContainer {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 20px;
}

.ButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}