@value primaryWhite100, primaryGreen400, primaryGreen500, secondaryBlue100 from '../colors/colors.module.css';
@value barHeight: 70px;

.horizontalBar {
    display: flex;
    align-items: center;
    position: fixed;
    box-sizing: border-box;
    background-color: primaryWhite100;
    height: barHeight;
    width: 100vw;
    padding: 0 20px;
    border-bottom: 2px solid secondaryBlue100;
    z-index: 5;
}

.menuIcon {
    margin-left: 32px;
    cursor: pointer;
    color: white;
}

.logoutBtn {
    margin: 0 0 0 auto;
    background-color: primaryGreen400;
    color: white;
}

.logoutBtn:hover {
    background-color: primaryGreen500;
}
