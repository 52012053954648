.settingsContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.videoStreamContainer {
    width: 256px;
    height: 192px;
    border-radius: 8px;
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
}

.settingsSelectContainer {
    width: 100%;
}

.settingsSelectContainer > div {
    margin-bottom: 10px
}

.pidWrapper{
    width: 100%;
}

.pid{
    width: calc(10% - 10px);
    height: 5px;
    display: inline-block;
    margin: 5px;
}
