@value primaryBlue300, primaryBlue500, secondaryGrey200 from '../../../../../../ui/colors/colors.module.css';

.tableRow {
    outline: secondaryGrey200 solid 1px;
}

.tableRow > td {
    width: auto;
    text-align: center;
    vertical-align: middle;
}

.viewResultLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: primaryBlue500;
    width: 100%;
    height: 100%;
    gap: 3px;
}

.viewResultLink:hover {
    cursor: pointer;
    color: primaryBlue300;
}

.iconClass {
    margin-top: -4px;
    width: 15px;
    height: 18px;
}
