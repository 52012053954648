.formContainer {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    gap: 20px;
    margin: 20px 0;
}

.formContainer > div {
    flex: 1 0 40%;
}
