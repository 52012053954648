@value primaryBlue100 ,primaryBlue500, primaryWhite200, secondaryBlue400, secondaryGrey200, secondaryGrey900 from '../../../../../ui/colors/colors.module.css';
@value toggleBtnWidth: 24px;
@value toggleBtnRightMargin: 12px;
@value toggleBtnContainerWidth: calc(toggleBtnWidth + toggleBtnRightMargin);

.document {
  border: 1px solid secondaryGrey200;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.toggleBtnContainer {
  width: toggleBtnContainerWidth;
}

.toggleBtn {
  border-radius: 50%;
  background-color: primaryWhite200;
  border: none;
  color: secondaryBlue400;
  width: toggleBtnWidth;
  height: toggleBtnWidth;
  transition: 250ms ease-in-out transform;
}

.toggleBtn:hover {
  cursor: pointer;
}

.open {
  background-color: primaryBlue100;
}

.open .toggleBtn {
  transform: rotateZ(90deg);
}

.contentContainer {
  width: calc(100% - toggleBtnContainerWidth);
  word-break: break-all;
}

.fileDownloadLink {
  font-weight: bold;
  color: primaryBlue500;
}

.title,
.uploadDate {
  color: secondaryGrey900;
}
