@value primaryWhite100, secondaryGrey500 from '../../../../../ui/colors/colors.module.css';
@value bottomButtonsWidth from '../form_content.module.css';

.basicInformationForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    background-color: primaryWhite100;
    box-shadow: 0 16px 30px rgba(55, 92, 170, 0.15);
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;
}

.formFlexGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.formFlexGrid > div {
    flex: 1 0 40%;
}

.buttonStyles {
    align-self: flex-end;
    margin-top: auto;
    width: bottomButtonsWidth;
}
