@value primaryWhite100 from '../../ui/colors/colors.module.css';

.container {
    width: 100%;
    min-height: 100vh;
}

.contentContainer {
    width: 100%;
    min-height: 80vh;
    background-color: primaryWhite100;
    box-shadow: 0 16px 30px rgba(55, 92, 170, 0.15);
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;
}

.tableStyle {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 8px;
}

.tableStyle th,td {
    padding: 10px;
}

.modalHeader > h1{
    font-size: 1em;
    text-align: center;
}

.organizationSettingsModal {
    width: 25%;
}
