@value primaryWhite100, primaryGradient100, secondaryBlue400, secondaryBlue500 from '../../ui/colors/colors.module.css';

.pageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: primaryGradient100;
}

.noticeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    width: 50vh;
    border-radius: 8px ;
    background-color: primaryWhite100;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.centerEmailLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.verificationNoticeHeader {
    font-weight: 700;
    font-size: 2rem;
    margin: 15px;
    text-align: center;
    text-transform: uppercase;
}

.verificationNoticeInfo {
    font-weight: 500;
    font-size: 0.875rem;
    margin: 8px 0;
    text-align: center;
}

.emailText {
    font-weight: 600;
    color: secondaryBlue400;
}

.resendLink {
    font-weight: 600;
    cursor: pointer;
    color: secondaryBlue400;
}

.resendLink:hover {
    color: secondaryBlue500;
}
