@value primaryGradient100, primaryWhite100, secondaryGrey200 from '../../colors/colors.module.css';

.switchContainer {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.switchContainer.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: secondaryGrey200;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: primaryWhite100;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background: primaryGradient100;
}

input:checked + .slider:before {
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.infoText {
    margin: 0 10px;
}
